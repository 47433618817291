<template>
  <div class="loanDetail">
    <mt-header :title="$t('loanDetails')">
      <router-link v-if="callBack" :to="callBack" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <mu-list>
        <mu-list-item>
          <mu-list-item-title>{{ $t('contractNo') }}</mu-list-item-title>
          <mu-list-item-action>{{ contractNo }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('loanAmount') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.applyAmount | formatMoney }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('loanTerm') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.displayLoanDays || item.loanTerms }} {{ $t('day') }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('filingDate') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.applyDate }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <template v-if="item.loanDate">
          <mu-list-item>
            <mu-list-item-title>{{ $t('paymentDate') }}</mu-list-item-title>
            <mu-list-item-action>{{ item.loanDate }}</mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </template>
        <template v-if="item.instalmentMark != 'Y' && item.repayDate">
          <mu-list-item>
            <mu-list-item-title>{{ $t('repaymentDate') }}</mu-list-item-title>
            <mu-list-item-action>{{ item.repayDate}}</mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </template>
        <template v-if="item.loanAmount && item.actulAmount">
          <mu-list-item>
            <mu-list-item-title>{{ $t('serviceFee') }}</mu-list-item-title>
            <mu-list-item-action>{{ (item.loanAmount - item.actulAmount) | formatMoney }}</mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </template>
        <template v-if="item.couponAmount">
          <mu-list-item>
            <mu-list-item-title>{{ $t('amountOfRepayment') }}</mu-list-item-title>
            <mu-list-item-action>{{ item.contractAmount | formatMoney }}</mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </template>
        <template v-if="item.couponAmount">
          <mu-list-item>
            <mu-list-item-title>{{ $t('repaymentReliefReward') }}</mu-list-item-title>
            <mu-list-item-action>{{ item.couponAmount | formatMoney }}</mu-list-item-action>
          </mu-list-item>
          <mu-divider></mu-divider>
        </template>
        <mu-list-item>
          <mu-list-item-title>{{ $t('repaymentAmount') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.repayAmount | formatMoney }}</mu-list-item-action>
        </mu-list-item>
        <mu-divider></mu-divider>
        <mu-list-item>
          <mu-list-item-title>{{ $t('contractStatus') }}</mu-list-item-title>
          <mu-list-item-action>{{ item.contractStatusName }}</mu-list-item-action>
        </mu-list-item>
      </mu-list>
    </div>

    <div class="stages" v-if="item.instalmentMark == 'Y'">
      <div class="item" v-for="(val, index) in (item.paymentSchedules || [])" :key="index">
        <div class="title">{{ $t('stage') }} {{ index + 1 }}/{{ item.paymentSchedules.length }}</div>
        <div>
          <mt-cell :title="$t('repaymentDate')">
            {{ val.loanPmtDueDate }}
          </mt-cell>
          <mt-cell :title="$t('repaymentAmount')">
            <template v-if="val.freeMark == 'Y'">
              <s>{{ val.totalAmt | formatMoney }}</s>
              <div class="free">
                {{ $t('free') }}
              </div>
            </template>
            <template v-else>
              {{ val.totalAmt | formatMoney }}
            </template>
          </mt-cell>
        </div>
      </div>
    </div>

    <div v-else-if="item.contractStatus == 'NORMAL' || item.contractStatus == 'OVERDUE'" class="bottom">
      <mt-button type="primary" size="large" @click="$router.push(`/mine/repaymentInfo?applyId=${contractNo}`)">{{
        $t("repayment")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { contractDetail } from '../../utils/api'
export default {
  name: 'LoanDetail',
  components: {
    callPhone
  },
  computed: {
    contractNo() {
      return this.$route.query.contractNo
    },
    callBack() {
      return this.$route.query.callBack
    }
  },
  data () {
    return {
      item: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      this.$NProgress.start()
      await this.getContractDetail()
      this.$indicator.close();
      this.$NProgress.done()
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.contractNo
        }
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.loanDetail {
  .detail {
    width: 16px;
    margin: 0 5px;
  }
  .content {
    background: white;
    margin: 10px;
    border-radius: 10px;
  }
  .stages {
    .item {
      margin: 10px;
      padding: 10px;
      border-radius: 5px;
      background: white;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
  }

  .mu-item-title {
    width: auto;
  }
}
</style>
